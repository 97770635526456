import { useEffect } from 'react'

/**
 * Like componentDidMount - fires on mount
 *
 * @export
 * @param {() => void} fn
 */
export function useDidMount(fn: () => void) {
  useEffect(
    fn,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}
