import { CMS, ProductsResponse } from '@odiupsk/up-api-client'
import { cmsAxios, upAxios } from '../../config'
import { maxPerPage } from '../utils/maxPerPage'
import { logError } from './utils'
import { errorDuringFetch } from '../utils/i18n/alerts'
import { TCmsTarget } from '../utils/types'

export class ApiProductsRepository {
  static async fetchProductThematicCategories(target: TCmsTarget) {
    try {
      const response = await cmsAxios.get<CMS.ProductThematicCategoryCollection>(
        `/product-thematic-categories`,
        {
          params: {
            per_page: maxPerPage,
            target,
          },
        }
      )

      return response.data.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchProductMainCategories(target: TCmsTarget) {
    try {
      const response = await cmsAxios.get<CMS.ProductMainCategoryCollection>(
        `/product-main-categories`,
        {
          params: {
            per_page: maxPerPage,
            target,
          },
        }
      )

      return response.data.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchProducts(target: TCmsTarget, withUnpublished: boolean = false) {
    try {
      const response = await cmsAxios.get<CMS.ProductCollection>(`/products`, {
        params: {
          per_page: maxPerPage,
          target,
          withUnpublished: withUnpublished ? 1 : 0,
        },
      })

      return response.data.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchApiProducts() {
    try {
      const response = await upAxios.get<ProductsResponse>(`/products`, {
        params: {
          per_page: maxPerPage,
        },
      })

      return response.data.data!
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }
}
