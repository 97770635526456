import { observable, computed, runInAction } from 'mobx'
import { find } from 'lodash-es'
import { Product, CMS } from '@odiupsk/up-api-client'
import { ApiProductsRepository } from './api/ApiProductsRepository'
import { scopeToTarget } from './utils/types'
import { SCOPES } from '../config/constants'
import { AuthCms } from './modules/auth/AuthCms'

type TCMSProduct = CMS.Product & { product_type_id?: number }

export type TApiProductEnhanced = {
  id: number
  code?: string
  title: string
  product_type_id?: number
  slug?: string
  icon: {
    '1x'?: string
  }
}

export class StoreMergedProducts {
  @observable private _apiProducts: Product[] = []

  @observable.ref private _productThematicCategories?: CMS.ProductThematicCategory[]

  @observable.ref private _productMainCategories?: CMS.ProductMainCategory[]

  @observable.ref private _publishedCmsProducts?: CMS.Product[]

  @observable.ref private _allCmsProducts?: CMS.Product[]

  @computed get productThematicCategories() {
    return this._productThematicCategories || []
  }

  @computed get productMainCategories() {
    return this._productMainCategories || []
  }

  @computed get svgProductIcons() {
    return this._allCmsProducts?.map(product => product.icon_xml)
  }

  fetch = async (role: SCOPES) => {
    await AuthCms.login()

    const res = await Promise.all<
      Product[],
      CMS.Product[],
      CMS.Product[],
      CMS.ProductThematicCategory[],
      CMS.ProductMainCategory[]
    >([
      ApiProductsRepository.fetchApiProducts(),
      ApiProductsRepository.fetchProducts(scopeToTarget(role)),
      ApiProductsRepository.fetchProducts(scopeToTarget(role), true),
      ApiProductsRepository.fetchProductThematicCategories(scopeToTarget(role)),
      ApiProductsRepository.fetchProductMainCategories(scopeToTarget(role)),
    ])
    runInAction(() => {
      this._apiProducts = res[0]
      this._publishedCmsProducts = res[1]
      this._allCmsProducts = res[2]
      this._productThematicCategories = res[3]
      this._productMainCategories = res[4]
    })
  }

  @computed get publishedCmsProducts(): TCMSProduct[] {
    const result: TCMSProduct[] = []

    for (const product of this._publishedCmsProducts ?? []) {
      const apiProduct = find(this._apiProducts, { id: product.up_id })

      result.push({ ...product, product_type_id: apiProduct?.product_type_id })
    }
    return result
  }

  @computed get allCmsProducts(): TCMSProduct[] {
    const result: TCMSProduct[] = []

    for (const product of this._allCmsProducts ?? []) {
      const apiProduct = find(this._apiProducts, { id: product.up_id })

      result.push({ ...product, product_type_id: apiProduct?.product_type_id })
    }
    return result
  }

  @computed get apiProducts(): TApiProductEnhanced[] {
    const result: TApiProductEnhanced[] = []

    for (const cmsProduct of this._publishedCmsProducts ?? []) {
      const product = find(this._apiProducts, { id: cmsProduct.up_id })
      if (!product) continue

      result.push({
        id: product.id,
        code: product.code,
        product_type_id: product.product_type_id,
        title: cmsProduct?.title ?? product.name,
        slug: cmsProduct?.slug,
        icon: { '1x': cmsProduct?.images.icon?.['1x'] },
      })
    }
    return result
  }

  @computed get allApiProducts(): TApiProductEnhanced[] {
    const result: TApiProductEnhanced[] = []

    for (const cmsProduct of this._allCmsProducts ?? []) {
      const product = find(this._apiProducts, { id: cmsProduct.up_id })
      if (!product) continue

      result.push({
        id: product.id,
        code: product.code,
        product_type_id: product.product_type_id,
        title: cmsProduct?.title ?? product.name,
        slug: cmsProduct?.slug,
        icon: { '1x': cmsProduct?.images.icon?.['1x'] },
      })
    }
    return result
  }

  /**
   * first try in cms products, then api  products
   */
  getProductById = (id: number) => {
    return find(this.allCmsProducts, { id }) ?? find(this.apiProducts, { id })
  }

  getProductName(id: number) {
    return this.getProductById(id)?.title
  }
}

export const storeMergedProducts = new StoreMergedProducts()
