import { t } from '@lingui/macro'
import { _ } from '../intl'

export const itemWasDeleted = _(t`Položka bola odstránená`)
export const errorDuringDelete = _(t`Pri odstraňovaní nastala chyba`)
export const itemWasAdded = _(t`Položka bola pridaná`)
export const itemWasSaved = _(t`Položka bola uložená`)
export const errorDuringSave = _(t`Pri ukladaní došlo k chybe`)

export const errorDuringFetch = _(t`Pri načítavaní nastala chyba`)

// export const errorActionGeneric = _(t`Pri akcii nastala chyba`)
