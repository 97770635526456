import { FieldState, FormState } from 'formstate'
import { DeepPartial } from 'ts-essentials'
import { SCOPES } from '../../config/constants'

/**
 * returns type `T` of `Array<T>`
 */
export type UnwrapFromArray<T> = T extends Array<infer U> ? U : T

/**
 * returns type `T` of `FieldState<T>`
 * FIXME: this should be either recursive, or operate on single FieldState, not record
 */
export type UnwrapFromFieldState<T> = {
  [K in keyof T]: T[K] extends FieldState<infer U> ? U : T[K]
}

export type UnwrapFromFormState<T> = { [K in keyof T]: T[K] extends FormState<infer U> ? U : T[K] }

/**
 * returns type `T` of `FieldState<T>`
 */
export type UnwrapFromPromise<T> = T extends Promise<infer U> ? U : T

/**
 * makes all properties of type optional - except those omitted
 * @example
 ```
 PartialExcept<{ id: string, foo: string }, 'id'> => { id: string, foo?: string | undefined }
 ```
 */
export type PartialExcept<T extends { id: string }, E extends keyof T = 'id'> = Partial<T> &
  Pick<T, E>

/**
 * creates Partial type of AxiosResponse with all fields optional!
 */
export type ApiResponseMock<T extends (...args: any[]) => any> = Partial<
  UnwrapFromPromise<ReturnType<T>>
>

/**
 * as name suggests
 * @example
 ```
 type Props = InferFunctionComponentProps<typeof Grid>
 ```
 */
export type InferFunctionComponentProps<P> = P extends React.FunctionComponent<infer P> ? P : never

// type OptionalExceptFor<T, TRequired extends keyof T> = Partial<T> & Pick<T, TRequired>

// type _PatchEntityInner<T extends { id: string }> = {
//   [K in keyof T]: T[K] extends Primitive
//     ? T[K]
//     : T[K] extends { id: string }
//     ? PatchEntity<T[K]>
//     : T[K]
// }

// export type PatchEntity<T extends { id: string }> = _PatchEntityInner<OptionalExceptFor<T, 'id'>>

export type PatchEntity<T> = DeepPartial<T>

export type TCmsTarget = 'public' | 'client' | 'end-user' | 'affiliate'

export function scopeToTarget(scope?: SCOPES): TCmsTarget {
  switch (scope) {
    case SCOPES.Affiliate:
      return 'affiliate'
    case SCOPES.Client:
      return 'client'
    case SCOPES.Holder:
      return 'end-user'
    default:
      return 'public'
  }
}
